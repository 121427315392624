:root{
    --swiper-navigation-color: #E5451F;
    --swiper-pagination-color: #E5451F;
  }
.button.is-primary{
  background-color: rgb(216, 0, 41);
  font-weight: 600;
}
.button.is-primary.is-active, .button.is-primary:active, .button.is-primary.is-hovered, .button.is-primary:hover{
  background-color: #c00225;
}
.button.is-primary.is-focused:not(:active), .button.is-primary:focus:not(:active) {
  box-shadow: 0 0 0 0.125em rgb(216 0 41 / 25%);
}
.button.is-primary.is-outlined {
  background-color: transparent;
  border-color: #D80029;
  color: #D80029;
}
.button.is-primary.is-outlined.is-focused, .button.is-primary.is-outlined.is-hovered, .button.is-primary.is-outlined:focus, .button.is-primary.is-outlined:hover {
  background-color: #D80029;
  border-color: #D80029;
  color: #fff;
}
.red{
  background-color: #D80029;
}
.orange{
  background-color: #E5451F;
}
.gray{
  background-color: #353535;
}
.sm-text-orange{
  color: #E5451F;
}
.sm-text-red{
  color: #D80029;
}
.sm-text-grey{
  color: #353535;
}
html, body{
scroll-behavior: smooth;
font-family: 'Lato', sans-serif;
overflow-x: hidden;
}

/* Navbar */
.sm-nav{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 10;
    padding: 0.75rem 6rem;
    box-shadow: 0px 4px 12px rgb(0 0 0 / 10%);
  }
  .sm-nav-logo{
    height: 2.5rem;
  }
  .sm-nav-menu{
    cursor: pointer;
    font-weight: 700;
  }
  .sm-menu-content{
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #fff;
    z-index: 5;
    display: none;
    transition: all 0.3s;
  }
  .sm-menu-content.active{
    display: block;
  }
  .sm-menu-content hr{
    width: 80%;
  }
  .sm-menu-wrapper{
    height: 100%;
  }
  .sm-menu-content-lists{
    font-size: 1.4rem;
    font-weight: 700;
  }
  .sm-menu-content-lists a{
    color: #353535;
  }
  .sm-menu-content-lists a:hover{
    color: #D80029;
  }
  .sm-nav .sm-socials-list svg{
    height: 1.8rem;
    width: 1.8rem;
  }
  .sm-nav-menu{
    display: inline-flex;
  }
  .sm-nav-menu a{
    color:#353535;
    transition: all 0.1s;
  }
  .sm-nav-menu a:hover{
    color: #D80029;
  }
  .footer-menu{
    font-weight: 700;
  }
  .footer{
    background-color: #fff;
    padding: 3rem 6rem;
    position: relative;
  }
  .footer::before{
    content: '';
    position: absolute;
    height: 1.25rem;
    top: 0;
    left: 0;
    right: 0;
    background: rgb(214,0,41);
    background: linear-gradient(90deg, rgba(214,0,41,1) 0%, rgba(229,69,31,1) 100%);  
  }
  .footer a {
    color: #353535;
    transition: all 0.1s;
  }
  .footer a:hover{
    opacity: 0.8;
    text-decoration: underline;
  }
  .sm-logo-footer{
    height: 12.5rem;
  }
  .sm-socials-list li{
    display: inline-block;
  }
  .sm-socials-list li a svg{
    height: 2rem;
    width: 2rem;
  }
  .sm-socials-list li a:hover{
    opacity: 0.8;
  }
  .sm-celgene{
    width: 100%;
    max-width: 16rem;
  }

@media screen and (max-width: 754px) {
    /* Navbar */
    .sm-nav{
        padding: 0.75rem 2rem;
    }
    .sm-nav-logo{
        height: 2rem;
    }
    .footer{
      padding: 3rem 2rem 2rem 2rem;
      text-align: center;
    }
}

@media screen and (min-width: 769px) and (max-width: 1023px){
    /* Navbar */
    .sm-nav{
        padding: 0.75rem 2rem;
    }
    .sm-nav-logo{
        height: 2.2rem;
    }
    .footer{
      padding: 2rem;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1215px){
    /* Navbar */
    .sm-nav{
        padding: 0.75rem 2rem;
    }
    .footer{
      padding: 2rem;
    }
}

@media screen and (min-width: 1216px) and (max-width: 1407px){
    /* Navbar */
    .sm-nav{
        padding: 0.75rem 3rem;
    }
    .footer{
      padding: 2rem 3rem;
    }
}

@media screen and (min-width: 1921px){
    html, body{
      font-size: 24px;
    }
  }
  @media screen and (min-width: 3840px){
    html, body{
      font-size: 48px;
    }
  }